import "./index.scss"

import React from "react"

import Radioactive from "../Radioactive"


const RadioactiveBox = () => (
    <div className="radioactive-box">
        <Radioactive/>
        <Radioactive/>
        <Radioactive/>
        <Radioactive/>
    </div>
)

export default RadioactiveBox
