import "./index.scss"

import React from "react"


const RectBorders = () => {
    const BorderItem = () => (
        <div className="rect-borders_item">
            <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="2" height="40" fill="#A5FA00"/>
                <rect width="40" height="2" fill="#A5FA00"/>
            </svg>
        </div>
    )

    return (
        <div className="rect-borders">
            <BorderItem/>
            <BorderItem/>
            <BorderItem/>
            <BorderItem/>
        </div>
    )
}

export default RectBorders
