import React from "react";
import "./index.scss";

const BurgerButton = ({ isOpen, setIsMenuOpen }) => (
  <div
    className={isOpen ? "menu-btn active" : "menu-btn"}
    onClick={() => setIsMenuOpen(!isOpen)}
  >
    <span className="bar"></span>
    <span className="bar"></span>
    <span className="bar"></span>
  </div>
);

export default BurgerButton;
