import "./index.scss";

import React, { useEffect } from "react";

import { gsap } from "gsap";

import PlayerPassCrocImg from "../../assets/crocodiles/player_pass_croc.png";

import { isMobileMedia, isDesktopMedia } from "../../utils";

const PlayerPassSection = () => {
  const isMobile = isMobileMedia();
  const isDesktop = isDesktopMedia();

  useEffect(() => {
    const tl1 = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".player-pass__content",
        },
      })
      .to(
        ".player-pass__img_box .croc-1",
        {
          transform: "rotate(-15deg)",
          right: !isMobile ? -40 : -50,
          bottom: -20,
          duration: 0.6,
          delay: 1,
          ease: "back.out(2)",
        },
        "<"
      )
      .to(
        ".player-pass__img_box .croc-3",
        {
          transform: "rotate(15deg)",
          left: !isMobile ? -40 : -50,
          bottom: -20,
          duration: 0.6,
          ease: "back.out(1.5)",
        },
        "<"
      );

    if (isDesktop) {
      const tl2 = gsap
        .timeline({
          scrollTrigger: {
            trigger: ".player-pass__content",
          },
        })
        .to(".desc1", {
          opacity: 1,
          duration: 1,
        })
        .to(".desc2", {
          opacity: 1,
          duration: 1,
        });

      return () => {
        tl1.clear();
        tl2.clear();
      };
    } else return () => tl1.clear();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="player-pass">
      <div className="player-pass__box">
        <div className="player-pass__content">
          <span className="flex-item desc1">
            The Swamplands dashboard is the place to go for everything CCC —
            Staking, $C4 Player Pass rewards, Liquidity provider benefits, or
            the Swamplands.
          </span>

          <div className="flex-item player-pass__img_box">
            <img
              className="croc-1"
              src={PlayerPassCrocImg}
              alt="player-pass croc"
            />
            <img
              className="croc-2"
              src={PlayerPassCrocImg}
              alt="player-pass croc"
            />
            <img
              className="croc-3"
              src={PlayerPassCrocImg}
              alt="player-pass croc"
            />
          </div>

          <span className="flex-item desc2">
            As well as a share of 30% of the profits from C4poker.io, The Player
            Pass Protocol will also pay 1% of the automated NFT dividend payouts
            from participating projects in the Swamplands to holders.
          </span>
        </div>
      </div>
      <div className="fog" />
    </div>
  );
};

export default PlayerPassSection;
