import "./index.scss";

import React, { useState } from "react";

import LogoImg from "../../assets/logo.svg";
import TwitterIcon from "../../assets/twitter_link.svg";
import DiscordIcon from "../../assets/discord_link.svg";
import BurgerButton from "../MobileMenu/BurgerButton";
import Menu from "../MobileMenu";

const AppHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <header className="header">
      <div className="header_logo">
        <a href="/">
          <img src={LogoImg} alt="logo" />
        </a>
      </div>

      <nav
        className="header_navs"
        onClick={() => document.body.classList.remove("forbid-page-scroll")}
      >
        <a className="flex-item" href="#token">
          TOKEN
        </a>
        <a className="flex-item" href="#nfts">
          NFT COLLECTIONS
        </a>
        <a className="flex-item" href="#swamplands">
          SWAMPLANDS
        </a>
        <a className="flex-item" href="#player-pass">
          $C4 PLAYER PASS
        </a>
        <a className="flex-item" href="#crocpad">
          CROCPAD
        </a>
        <a className="flex-item" href="#investments">
          $C4 INVESTMENTS
        </a>
        <a className="flex-item" href="#team">
          TEAM
        </a>
      </nav>

      <div className="header_links">
        <a
          className="flex-item"
          href="https://twitter.com/CardanoCrocClub"
          target="_blank"
          rel="noreferrer"
        >
          <img src={TwitterIcon} alt="twitter" />
        </a>
        <a
          className="flex-item"
          href="https://discord.gg/7vgrzCSv3w"
          target="_blank"
          rel="noreferrer"
        >
          <img src={DiscordIcon} alt="discord" />
        </a>
      </div>
      <BurgerButton isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Menu isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </header>
  );
};

export default AppHeader;
