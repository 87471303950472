import React from "react";
import "../index.scss";

const StackingRewardTable = ({ data }) => {
  const { heading, collumns, rows, footer } = data;

  return (
    <div className="stackingReward_table">
      <div className="table_head">{heading}</div>
      <div className="table_row">
        {collumns.map((collumn) => (
          <div className="table_cell" key={collumn}>
            {collumn}
          </div>
        ))}
      </div>
      <div className="table_row">
        Rarity ranks are taken from{" "}
        <a href="https://cnft.tools/" target="_blank" rel="noreferrer">
          {" "}
          cnft.tools
        </a>
      </div>
      {rows.map((row) => (
        <div className="table_row" key={Object.keys(row)[0]}>
          <div className="table_cell">{Object.keys(row)[0]}</div>
          <div className="table_cell">{Object.values(row)[0]}</div>
        </div>
      ))}
      {footer ? (
        <div className="table_footer">
          <p>{footer}</p>
        </div>
      ) : (
        <div className="table_row"></div>
      )}
    </div>
  );
};

export default StackingRewardTable;
