import "./index.scss"

import React from "react"


const ScrollArrow = ({className}) => (
    <div className={`scroll-arrow ${className}`}/>
)

export default ScrollArrow
