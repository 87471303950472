import React, { useEffect } from "react";
import "./index.scss";
import { babyData, cardanoData, radioactiveData } from "./tablesData";
import StackingRewardTable from "./StackingRewardTable";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const StackingRewardSection = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap
      .timeline({ scrollTrigger: { trigger: ".stackingReward_table1" } })
      .to(".stackingReward_table1", {
        transform: "none",
        opacity: 1,
        duration: 1.5,
        delay: 1,
      });
    gsap
      .timeline({ scrollTrigger: { trigger: ".stackingReward_table2" } })
      .to(".stackingReward_table2", {
        transform: "none",
        opacity: 1,
        duration: 1.5,
        delay: 1,
      });
    gsap
      .timeline({ scrollTrigger: { trigger: ".stackingReward_table3" } })
      .to(".stackingReward_table3", {
        transform: "none",
        opacity: 1,
        duration: 1.5,
        delay: 1.5,
      });
  });

  return (
    <div className="stackingReward">
      <div className="stackingReward_container">
        <div className="flex-item stackingReward_table1">
          <StackingRewardTable data={cardanoData} />
        </div>
        <div className="flex-item stackingReward_table2">
          <StackingRewardTable data={radioactiveData} />
        </div>
        <div className="flex-item stackingReward_table3">
          <StackingRewardTable data={babyData} />
        </div>
      </div>
    </div>
  );
};

export default StackingRewardSection;
