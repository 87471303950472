import "./index.scss";

import React, { useEffect, useMemo, useRef } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import LeftCroc1 from "../../assets/crocodiles/left_group/croc_1.png";
import LeftCroc2 from "../../assets/crocodiles/left_group/croc_2.png";
import LeftCroc3 from "../../assets/crocodiles/left_group/croc_3.png";
import LeftCroc4 from "../../assets/crocodiles/left_group/croc_4.png";
import LeftCroc5 from "../../assets/crocodiles/left_group/croc_5.png";
import RightCroc1 from "../../assets/crocodiles/right_group/croc_1.png";
import RightCroc2 from "../../assets/crocodiles/right_group/croc_2.png";
import RightCroc3 from "../../assets/crocodiles/right_group/croc_3.png";
import RightCroc4 from "../../assets/crocodiles/right_group/croc_4.png";
import RightCroc5 from "../../assets/crocodiles/right_group/croc_5.png";

import ScrollArrow from "../../toolkits/ScrollArrow";
import RadioactiveBox from "../../toolkits/RadioactiveBox";
import RectBorders from "../../toolkits/RectBorders";

import { getRem, isDesktopMedia } from "../../utils";
import SmallButton from "../../toolkits/SmallButton";

const crocodiles = [
  { left: LeftCroc1, right: RightCroc1 },
  { left: LeftCroc2, right: RightCroc2 },
  { left: LeftCroc3, right: RightCroc3 },
  { left: LeftCroc4, right: RightCroc4 },
  { left: LeftCroc5, right: RightCroc5 },
];

const AboutUsPopup = ({ ext }) => (
  <div className={`welcome_about-us${ext ? "-ext" : ""} welcome_feature_item`}>
    <RectBorders />
    <RadioactiveBox />

    <div className="welcome_about-us_logo" />

    {!ext ? (
      <div className="welcome_about-us_content">
        <div className="flex-item">
          <span>The Cardano Crocs Club (CCC)</span> is a Cardano-based NFT
          gaming ecosystem built around community and strong values.
        </div>
        <div className="flex-item">
          <span>Here at CCC, we are all Crocs —</span> we love our swamp,
          believe in Web3, and create utility to provide opportunities for our
          holders.
        </div>
      </div>
    ) : (
      <div className="welcome_about-us_content">
        <div className="flex-item">
          CCC is open to the world of NFTs and partnerships and is striving to
          build what we call home: the Swamp — a common-ground where other
          projects and Web3 developers can grow together.
        </div>
        <SmallButton
          className="flex-item"
          onClick={() =>
            window.open("https://discord.com/invite/cardanocrocsclub")
          }
        >
          JOIN NOW
        </SmallButton>
        <div className="flex-item">or scroll down for more info</div>
      </div>
    )}
  </div>
);

const AboutUsPopupTotal = () => (
  <div
    className={`welcome_about-us-ext welcome_feature_item welcome_about-us_total`}
  >
    <RectBorders />
    <RadioactiveBox />

    <div className="welcome_about-us_logo" />

    <div className="welcome_about-us_content">
      <div className="flex-item">
        <div className="flex-item">
          <span>
            <span>The Cardano Crocs Club (CCC)</span> is a Cardano-based NFT
            gaming ecosystem built around community and strong values.
          </span>
        </div>
        <div className="flex-item">
          <span>
            <span>Here at CCC, we are all Crocs</span> — we love our swamp,
            believe in Web3, and create utility to provide opportunities for our
            holders.
          </span>
        </div>
        <div className="flex-item">
          <span>
            CCC is open to the world of NFTs and partnerships and is striving to
            build what we call home: the Swamp — a common-ground where other
            projects and Web3 developers can grow together.
          </span>
        </div>
      </div>
      <SmallButton
        className="flex-item"
        onClick={() =>
          window.open("https://discord.com/invite/cardanocrocsclub")
        }
      >
        JOIN NOW
      </SmallButton>
      <div className="flex-item">or scroll down for more info</div>
    </div>
  </div>
);

const WelcomeSection = () => {
  const isDesktop = isDesktopMedia();

  const timelineWelcome = useMemo(() => gsap.timeline({ paused: true }), []);

  const latestActiveCroc = useRef(0);
  const welcomeDayRef = useRef(null);
  const intervalId = useRef(null);

  const runCountAnimation = (id) => {
    let frame = 0;
    const animationDuration = 2000;
    const frameDuration = 1000 / 60;
    const el = document.querySelector(id);
    const countTo = parseInt(el.innerHTML, 10);
    const totalFrames = Math.round(animationDuration / frameDuration);
    const counter = setInterval(() => {
      frame++;
      const progress = (frame / totalFrames) * (2 - frame / totalFrames);
      const currentCount = Math.round(countTo * progress);
      if (parseInt(el.innerHTML, 10) !== currentCount) {
        el.innerHTML = currentCount;
      }
      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  };

  useEffect(() => {
    // Initialize preview animation
    timelineWelcome
      .to(".welcome", {
        backgroundSize: "175% 175%",
        duration: 4,
      })
      .to(
        [".welcome_croc1", ".welcome_croc1_ext"],
        {
          transform: "rotate(-30deg)",
          bottom: -200,
          left: -200,
          duration: 3,
          opacity: 0,
        },
        "<"
      )
      .to(
        [".welcome_croc2", ".welcome_croc2_ext"],
        {
          bottom: -200,
          right: -200,
          transform: "rotate(30deg)",
          duration: 3,
          opacity: 0,
          onComplete: () =>
            document
              .querySelectorAll(".welcome > img")
              .forEach((el) => el.remove()),
        },
        "<"
      )
      .to(
        [".welcome_offer", ".welcome_scroll-label"],
        {
          transform: "scale(3)",
          opacity: 0,
          duration: 3,
          filter: `blur(${getRem(5)})`,
          paddingTop: getRem(50),
          bottom: -100,
          onComplete: () => {
            document.getElementsByClassName("welcome_offer")[0].remove();
            document.getElementsByClassName("welcome_scroll-label")[0].remove();
            document
              .querySelectorAll(".welcome .radioactive")
              .forEach((el) => (el.style.display = "flex"));
          },
        },
        "<"
      )
      .to(
        ".welcome_feature_gaming",
        {
          opacity: 1,
          delay: 3.5,
          duration: 1,
          transform: "scale(1) rotate(-15deg)",
          display: "flex",
        },
        "<"
      )

      .to(".welcome_feature_community", {
        opacity: 1,
        delay: 0,
        duration: 1,
        transform: "scale(1) rotate(15deg)",
        display: "flex",
      })
      .to(
        ".welcome_feature_gaming",
        {
          opacity: 0.5,
          duration: 1,
          filter: `blur(${getRem(5)})`,
          transform: "scale(2) rotate(-15deg) translate(-100px, -100px)",
        },
        "<"
      )

      .to(".welcome_feature_gaming", {
        transform: "scale(2.5) rotate(-15deg) translate(-125x, -125px)",
        opacity: 0,
        duration: 1,
        onComplete: () =>
          document.getElementsByClassName("welcome_feature_gaming")[0].remove(),
      })
      .to(
        ".welcome_feature_fun",
        {
          opacity: 1,
          delay: 0,
          duration: 1,
          transform: "scale(1) rotate(-15deg)",
          display: "flex",
        },
        "<"
      )
      .to(
        ".welcome_feature_community",
        {
          opacity: 0.5,
          duration: 1,
          filter: `blur(${getRem(5)})`,
          transform: "scale(2) rotate(15deg) translate(100px, -100px)",
        },
        "<"
      )

      .to(".welcome_feature_community", {
        transform: "scale(2.5) rotate(15deg) translate(125x, -125px)",
        opacity: 0,
        duration: 1,
        onComplete: () =>
          document
            .getElementsByClassName("welcome_feature_community")[0]
            .remove(),
      })
      .to(
        ".welcome_feature_staking",
        {
          opacity: 1,
          delay: 0,
          duration: 1,
          transform: "scale(1) rotate(11.26deg)",
          display: "flex",
        },
        "<"
      )
      .to(
        ".welcome_feature_fun",
        {
          opacity: 0.5,
          duration: 1,
          filter: `blur(${getRem(5)})`,
          transform: "scale(2) rotate(-15deg) translate(100px, 100px)",
        },
        "<"
      )

      .to(".welcome_feature_staking", {
        opacity: 0,
        duration: 1,
        transform: "scale(2.5) rotate(11.26deg) translate(-75px, 100px)",
        onComplete: () =>
          document
            .getElementsByClassName("welcome_feature_staking")[0]
            .remove(),
      })
      .to(
        ".welcome_feature_fun",
        {
          opacity: 0,
          duration: 1,
          transform: "scale(2.5) rotate(-15deg) translate(125px, 125px)",
          onComplete: () =>
            document.getElementsByClassName("welcome_feature_fun")[0].remove(),
        },
        "<"
      )
      .to(
        ".welcome",
        {
          duration: 0.2,
          backgroundSize: "0 0",
          opacity: 0.7,
        },
        "<"
      )
      .set(
        ".welcome_day",
        {
          backgroundSize: "175% 175%",
        },
        ">"
      )
      .to(".welcome_day", {
        backgroundSize: "100% 100%",
        duration: 0.8,
      })
      .set(".welcome", {
        opacity: 1,
        animation: "none",
      })
      .to(
        ".welcome_about-us",
        {
          opacity: 1,
          duration: 1,
          display: "flex",
        },
        "<"
      )

      .to(".welcome_about-us", {
        delay: 4,
        opacity: 0,
        duration: 1,
        onComplete: () =>
          document.getElementsByClassName("welcome_about-us")[0].remove(),
      })
      .to(
        ".welcome_about-us-ext",
        {
          opacity: 1,
          duration: 1,
          display: "flex",
        },
        "<"
      )
      .to(
        ".welcome_day",
        {
          backgroundPositionX: "-100vw, 0",
          duration: 1.5,
        },
        "<"
      );

    if (isDesktop) {
      welcomeDayRef.current.addEventListener("wheel", runPreviewAnimation);
    }
    // eslint-disable-next-line
  }, []);

  const runPreviewAnimation = () => {
    clearInterval(intervalId.current);
    welcomeDayRef.current.removeEventListener("wheel", runPreviewAnimation);

    timelineWelcome.play().then(() => {
      document.body.classList.remove("forbid-page-scroll");
      timelineWelcome.clear();
    });
  };

  const changeCrocImages = () => {
    const newCrocId = (latestActiveCroc.current + 1) % 5;

    gsap
      .timeline()
      .set([".welcome_croc1", ".welcome_croc2"], {
        opacity: 1,
      })
      .set(".welcome_croc1_ext", {
        attr: {
          src: crocodiles[newCrocId].left,
        },
        opacity: 0,
      })
      .set(".welcome_croc2_ext", {
        attr: {
          src: crocodiles[newCrocId].right,
        },
        opacity: 0,
      })
      .to([".welcome_croc1", ".welcome_croc2"], {
        opacity: 0,
        duration: 2.7,
      })
      .to(
        ".welcome_croc1_ext",
        {
          src: crocodiles[newCrocId].left,
          opacity: 1,
          duration: 2.7,
        },
        "<"
      )
      .to(
        ".welcome_croc2_ext",
        {
          src: crocodiles[newCrocId].right,
          opacity: 1,
          duration: 2.7,
        },
        "<"
      )
      .set(".welcome_croc1", {
        attr: {
          src: crocodiles[newCrocId].left,
        },
      })
      .set(".welcome_croc2", {
        attr: {
          src: crocodiles[newCrocId].right,
        },
      })
      .then(() => {
        latestActiveCroc.current = newCrocId;
      });
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (!isDesktop) {
      gsap
        .timeline({ scrollTrigger: { trigger: ".welcome_day_alt" } })
        .to(".welcome_day_alt", {
          delay: 0.8,
        })
        .set(".welcome_day_alt", {
          filter: "brightness(100%)",
        })
        .to(".welcome_about-us_total", {
          delay: 0.5,
          duration: 0.5,
          opacity: 1,
        });
    }

    const growingProps = { opacity: 1, duration: 0.5 };

    gsap
      .timeline({ scrollTrigger: { trigger: ".welcome_growing_option_ada" } })
      .to(
        ".welcome_growing_title",
        {
          delay: 0.5,
          ...growingProps,
          onComplete: () => runCountAnimation("#community-count"),
        },
        "<"
      )
      .to(".welcome_growing_option_community", growingProps)
      .to(
        ".welcome_growing_option_community h3",
        { transform: "none", opacity: 1, duration: 0.5 },
        "<"
      )
      .to(
        ".welcome_growing_option_community .left-corner",
        {
          transform: "none",
          opacity: 1,
          duration: 1,
          onComplete: () => runCountAnimation("#nft-count"),
        },
        "<"
      )
      .to(
        ".welcome_growing_option_community .right-corner",
        { transform: "none", opacity: 1, duration: 1 },
        "<"
      )

      .to(".welcome_growing_option_nft", growingProps)
      .to(
        ".welcome_growing_option_nft h3",
        { transform: "none", opacity: 1, duration: 0.5 },
        "<"
      )
      .to(
        ".welcome_growing_option_nft .left-corner",
        { transform: "none", opacity: 1, duration: 1 },
        "<"
      )
      .to(
        ".welcome_growing_option_nft .right-corner",
        // ???
        {
          transform: "none",
          opacity: 1,
          duration: 1,
          onComplete: () => runCountAnimation("#ada-count"),
        },
        "<"
      )

      .to(".welcome_growing_option_ada", growingProps)
      .to(
        ".welcome_growing_option_ada h3",
        { transform: "none", opacity: 1, duration: 0.5 },
        "<"
      )
      .to(
        ".welcome_growing_option_ada .left-corner",
        { transform: "none", opacity: 1, duration: 1 },
        "<"
      )
      .to(
        ".welcome_growing_option_ada .right-corner",
        { transform: "none", opacity: 1, duration: 1 },
        "<"
      )

      .to(".welcome_growing_option_established", growingProps)
      .to(
        ".welcome_growing_option_established h3",
        { transform: "none", opacity: 1, duration: 0.5 },
        "<"
      )
      .to(
        ".welcome_growing_option_established .left-corner",
        { transform: "none", opacity: 1, duration: 1 },
        "<"
      )
      .to(
        ".welcome_growing_option_established .right-corner",
        { transform: "none", opacity: 1, duration: 1 },
        "<"
      )

      .to(".welcome_growing_join", growingProps);

    gsap
      .timeline({ scrollTrigger: { trigger: ".welcome_growing_option_ada" } })
      .to(".welcome_growing_option_community .counter", {
        animation: "counterCommunity 5s alternate ease-in-out",
        counterReset: "communityCounter var(--communityCounter)",
      })
      .to(".welcome_growing_option_nft .counter", {
        animation: "counterNft 4s alternate ease-in-out forwards",
        counterReset: "nftCounter var(--nftCounter)",
      })
      .to(".welcome_growing_option_ada .counter", {
        animation: "counterAda 3s alternate ease-in-out forwards",
        counterReset: "adaCounter var(--adaCounter)",
      });

    intervalId.current = setInterval(changeCrocImages, 4000);

    return () => {
      clearInterval(intervalId.current);
      // eslint-disable-next-line
      welcomeDayRef?.current.removeEventListener("wheel", runPreviewAnimation);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="welcome_section">
      <div className="welcome_day" ref={welcomeDayRef}>
        <div className="welcome">
          <div className="welcome_offer">
            <span>Welcome to</span>
            <span>CARDANO</span>
            <span>CROCS CLUB</span>
            <span>The best gaming NFT community on Cardano</span>
          </div>
          <div className="welcome_scroll-label">
            <span className="flex-item">SCROLL DOWN</span>
            <ScrollArrow className="flex-item" />
            <span className="flex-item">DISCOVER MORE</span>
          </div>

          <img
            className="welcome_croc-img welcome_croc1"
            src={crocodiles[0].left}
            alt="left croc"
          />
          <img
            className="welcome_croc-img welcome_croc1_ext"
            src={crocodiles[0].left}
            alt="left croc"
          />
          <img
            className="welcome_croc-img welcome_croc2"
            src={crocodiles[0].right}
            alt="right croc"
          />
          <img
            className="welcome_croc-img welcome_croc2_ext"
            src={crocodiles[0].right}
            alt="right croc"
          />
          {!isDesktop && <div className="welcome_ribbon" />}

          {isDesktop && (
            <>
              <div className="welcome_feature_item welcome_feature_gaming">
                <RectBorders />
                <RadioactiveBox />

                <span>GAMING</span>
              </div>
              <div className="welcome_feature_item welcome_feature_community">
                <RectBorders />
                <RadioactiveBox />

                <span>COMMUNITY</span>
              </div>
              <div className="welcome_feature_item welcome_feature_fun">
                <RectBorders />
                <RadioactiveBox />

                <span>FUN</span>
              </div>
              <div className="welcome_feature_item welcome_feature_staking">
                <RectBorders />
                <RadioactiveBox />

                <span>STAKING FOR WEB3</span>
              </div>

              <AboutUsPopup />
              <AboutUsPopup ext={true} />
            </>
          )}
        </div>
      </div>
      {!isDesktop && (
        <div className="welcome_day_alt">
          <AboutUsPopupTotal />
        </div>
      )}
      <div className="welcome_growing">
        <div className="flex-item welcome_growing_title">
          The community
          <br /> is growing every day
        </div>

        <div className="flex-item welcome_growing_option welcome_growing_option_community">
          <h3>COMMUNITY</h3>
          <div>
            <span className="left-corner">[</span>
            <span className="counter" id="community-count">
              15000
            </span>
            <span className="right-corner">]</span>
          </div>
        </div>

        <div className="flex-item welcome_growing_option welcome_growing_option_nft">
          <h3>NFTs Created</h3>
          <div>
            <span className="left-corner">[</span>
            <span className="counter" id="nft-count">
              25000
            </span>
            <span className="right-corner">]</span>
          </div>
        </div>
        <div className="flex-item welcome_growing_option welcome_growing_option_ada">
          <h3>Total turnover of ADA</h3>
          <div>
            <span className="left-corner">[</span>
            <span className="counter" id="ada-count">
              10000000
            </span>
            <span className="right-corner">]</span>
          </div>
        </div>
        <div className="flex-item welcome_growing_option welcome_growing_option_established">
          <h3>Established</h3>
          <div>
            <span className="left-corner">[</span>09/2021
            <span className="right-corner">]</span>
          </div>
        </div>

        <div className="flex-item welcome_growing_join">
          <div>
            <span>It's time to join us</span>
            <SmallButton
              onClick={() =>
                window.open("https://discord.com/invite/cardanocrocsclub")
              }
              blackMode
            >
              JOIN
            </SmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
