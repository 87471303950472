import "./index.scss";

import React from "react";

import Croc3Img from "../../assets/crocodiles/croc_3.png";
import RadiactiveDecorationImg from "../../assets/radioactive_decoration.svg";
import RadioactiveBackgroundImg from "../../assets/radioactive_background.svg";


const CrocpadSection = () => {

    return (
        <div className="crocpad">
            <div className="crocpad__wrapper">
            <div className="flex-item crocpad__content">
                <span className="flex-item">
                Crocpad is a platform that provides comprehensive assistance and
                advice to project teams regarding the release and launch of projects.
                </span>
                <span className="flex-item">
                We provide a full range of services from pre-release advice to release
                support and post-launch marketing. Our goal is to enable the project
                teams to focus on the development and creation of the product, while
                we take care of marketing and building the user base.
                </span>
                <span className="flex-item">
                We are looking for strong teams with unique and innovative ideas to
                develop the cryptocurrency industry. If you fit this description,
                apply now!
                </span>
            </div>
            <div className="flex-item crocpad__img">
                <img src={Croc3Img} alt="crocodile" />

                <img src={RadioactiveBackgroundImg} alt="radioactive background" />
            </div>

            <img
                className="top-left-decor"
                src={RadiactiveDecorationImg}
                alt="radioactive decor"
            />
            <img
                className="top-right-decor"
                src={RadiactiveDecorationImg}
                alt="radioactive decor"
            />
            <img
                className="bottom-left-decor"
                src={RadiactiveDecorationImg}
                alt="radioactive decor"
            />
            <img
                className="bottom-right-decor"
                src={RadiactiveDecorationImg}
                alt="radioactive decor"
            />
            </div>
        </div>
    )
};

export default CrocpadSection;
