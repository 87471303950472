export const cardanoData = {
  heading: "Cardano Crocs Club",
  collumns: ["Rarity Rank", "Daily $c4"],
  rows: [
    { "SPECIAL EDITION": 200 },
    { "2-1000": 160 },
    { "1001-2000": 110 },
    { "2001-3000": 78 },
    { "3001-4000": 56 },
    { "4001-5000": 40 },
    { "5001-6000": 30 },
    { "6001-7000": 24 },
    { "7001-7700": 22 },
  ],
  footer: "",
};

export const radioactiveData = {
  heading: "Radioactive Crocs Club",
  collumns: ["Rarity Rank", "Daily $c4"],
  rows: [
    { "1-500": 100 },
    { "501-1000": 80 },
    { "1001-2000": 55 },
    { "2001-3000": 39 },
    { "3001-4000": 28 },
    { "4001-5000": 20 },
    { "5001-6000": 15 },
    { "6001-7000": 12 },
    { "7001-8000": 11 },
    { "8001-9975": 10 },
  ],
};

export const babyData = {
  heading: "Baby Crocs Club",
  collumns: ["Rarity Rank", "% boost"],
  rows: [
    { "Special Edition": "100%" },
    { "2-500": "80%" },
    { "501-1000": "70%" },
    { "1001-2000": "60%" },
    { "2001-3000": "50%" },
    { "3001-4000": "40%" },
    { "4001-5000": "35%" },
    { "5001-6000": "30%" },
  ],
  footer:
    "Baby Crocs automatically pair up with your highest generating Crocs (from both collections).",
};
