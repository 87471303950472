import "./index.scss"

import React from "react"

const SmallButton = ({children, onClick, blackMode, className, ...props}) => (
    <div className={`small-button ${blackMode ? "black-mode" : ""} ${className}`} onClick={onClick}>
        <button {...props}>{children}</button>
    </div>
)

export default SmallButton
