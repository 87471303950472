import React, { useEffect } from "react";
import "./index.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Radioactive from "../../toolkits/Radioactive";
import c4coinImg from "../../assets/token/c4coin.png";
import usdc4coinImg from "../../assets/token/usdc4coin.png";
import numberBg from "../../assets/token/numberBg.svg";
import arrowIcon from "../../assets/token/arrow.svg";
import { getRem, isMobileMedia } from "../../utils";

const TokenSection = () => {
  const isMobile = isMobileMedia();

  const TokenBorder = ({ className }) => (
    <div className={className}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="2" height="40" fill="white" />
        <rect width="40" height="2" fill="white" />
      </svg>
    </div>
  );

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    var tl = gsap.timeline({
      scrollTrigger: { trigger: ".token_container" },
    });

    if (!isMobile) {
      tl.to(".c4", {
        duration: 1,
        delay: 0.5,
        opacity: 1,
        transform: `translate(${getRem(-50)}, 0)`,
      })
        .to(
          ".usdc4",
          {
            duration: 1,
            delay: 0,
            opacity: 1,
            transform: `translate(${getRem(50)}, 0)`,
          },
          "<"
        )
        .to(".c4", {
          duration: 1,
          transform: "none",
        })
        .to(
          ".usdc4",
          {
            duration: 1,
            transform: "none",
          },
          "<"
        )
        .to(".token_disclaimerBox", {
          duration: 2.5,
          delay: 0,
          opacity: 1,
        });
    }
  }, [isMobile]);

  return (
    <div className="token_container">
      <div className="flex-item token_card c4">
        <div className="token_box">
          <Radioactive />
          <Radioactive />
          <Radioactive />
          <Radioactive />
          <TokenBorder className="token_box_border" />
          <TokenBorder className="token_box_border" />
          <TokenBorder className="token_box_border" />
          <TokenBorder className="token_box_border" />

          <img src={c4coinImg} alt="coin" />
        </div>
        <div className="token_contentBox">
          <h4>$c4</h4>
          <p>
            The Cardano Crocs Club Coin ($C4) is the native token for the
            Cardano Crocs Club. It was created in December 2021, it is the token
            that fuels the Swamplands ecosystem and everything happening in the
            project revolves around $C4.
          </p>
        </div>
      </div>
      <div className="flex-item token_card usdc4">
        <div className="token_box">
          <Radioactive />
          <Radioactive />
          <Radioactive />
          <Radioactive />
          <TokenBorder className="token_box_border" />
          <TokenBorder className="token_box_border" />
          <TokenBorder className="token_box_border" />
          <TokenBorder className="token_box_border" />

          <img src={usdc4coinImg} alt="coin" />
        </div>
        <div className="token_contentBox">
          <h4>USDC4</h4>
          <p>
            USDC4 is a stablecoin that is based on smart contracts. There is
            USDC4 on one end of the smart contract, and USDC on the other end
            (can go cross-chain). 1 USDC4 = 1 USDC.
          </p>
        </div>
      </div>
      <div className="flex-item token_disclaimerBox">
        <p>
          The conversion from one to another will be made by using several
          liquidity pools and liquidity providers. This system allows us to
          carry out cross chain transactions in minutes and all via the one
          interface — this means we can accept any crypto currency or fiat in
          the application, while still keeping resources in $C4 token.
        </p>
        <TokenBorder className="token_disclaimerBox_border" />
        <TokenBorder className="token_disclaimerBox_border" />
        <TokenBorder className="token_disclaimerBox_border" />
        <TokenBorder className="token_disclaimerBox_border" />
      </div>
      <div className="flex-item token_getC4Box">
        <h3>How can i get $C4?</h3>
        <ul>
          <li>
            <a
              href="https://swamplands.cardanocrocsclub.com/staking/crocs-staking"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="numberWrapper">
                <img src={numberBg} alt="num" />
                <p>1</p>
              </span>
              <div>
                <p>Obtain $C4 by staking</p>
                <p>your Crocs on the dashboard</p>
              </div>
              <img src={arrowIcon} alt="arrow" />
            </a>
          </li>
          <li>
            <a
              href="https://swamplands.cardanocrocsclub.com/c4-invest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="numberWrapper">
                <img src={numberBg} alt="num" />
                <p>2</p>
              </span>
              <div>
                <p>Help crowdfund projects, and</p>
                <p>benefit from the rewards together</p>
              </div>
              <img src={arrowIcon} alt="arrow" />
            </a>
          </li>
          <li>
            <a
              href="https://c4poker.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="numberWrapper">
                <img src={numberBg} alt="num" />
                <p>3</p>
              </span>
              <div>
                <p>Win $C4 in poker or</p>
                <p>other games. Or not ;)</p>
              </div>

              <img src={arrowIcon} alt="arrow" />
            </a>
          </li>
          <li>
            <a
              href="https://exchange.sundaeswap.finance/#/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="numberWrapper">
                <img src={numberBg} alt="num" />
                <p>4</p>
              </span>
              <div>
                <p>Buy $C4 on an exchange.</p>
                <p>For example: sundaeswap.finance</p>
              </div>

              <img src={arrowIcon} alt="arrow" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TokenSection;
