import "./index.scss";

import React from "react";

import LogoImg from "../../assets/logo.svg";
import TwitterIcon from "../../assets/twitter_link.svg";
import DiscordIcon from "../../assets/discord_link.svg";

import { isDesktopMedia } from "../../utils";
import WhitePaperPDF from "../../pdf/Whitepaper.pdf";

const AppFooter = () => {
  const isDekstop = isDesktopMedia();

  return (
    <footer className="footer">
      <div className="header">
        <div className="header_logo flex-item">
          <img src={LogoImg} alt="logo" />
        </div>

        {isDekstop && (
          <nav className="header_navs flex-item">
            <a href="#token" className="flex-item">
              TOKEN
            </a>
            <a href="#nfts" className="flex-item">
              NFT COLLECTIONS
            </a>
            <a href="#swamplands" className="flex-item">
              SWAMPLANDS
            </a>
            <a href="#player-pass" className="flex-item">
              $C4 PLAYER PASS
            </a>
            <a href="#crocpad" className="flex-item">
              CROCPAD
            </a>
            <a href="#investments" className="flex-item">
              $C4 INVESTMENTS
            </a>
            <a href="#team" className="flex-item">
              TEAM
            </a>
          </nav>
        )}

        <div className="header_links flex-item">
          <a
            href="https://twitter.com/CardanoCrocClub"
            target="_blank"
            rel="noreferrer"
            className="flex-item"
          >
            <img src={TwitterIcon} alt="twitter" />
          </a>
          <a
            href="https://discord.gg/7vgrzCSv3w"
            target="_blank"
            rel="noreferrer"
            className="flex-item"
          >
            <img src={DiscordIcon} alt="discord" />
          </a>
        </div>
      </div>

      {!isDekstop && (
        <nav className="header_navs-tablet">
          <div className="flex-item">
            <a href="#token" className="flex-item">
              TOKEN
            </a>
            <a href="#nfts" className="flex-item">
              NFT COLLECTIONS
            </a>
            <a href="#swamplands" className="flex-item">
              SWAMPLANDS
            </a>
            <a href="#player-pass" className="flex-item">
              $C4 PLAYER PASS
            </a>
          </div>
          <div className="flex-item">
            <a href="#crocpad" className="flex-item">
              CROCPAD
            </a>
            <a href="#investments" className="flex-item">
              $C4 INVESTMENTS
            </a>
            <a href="#team" className="flex-item">
              TEAM
            </a>
          </div>
        </nav>
      )}

      <div className="footer_rights">
        <div className="flex-item footer_rights_sect1">
          <span>© 2023 Cardano Crocs Club. All Rights Reserved</span>
        </div>
        <div className="flex-item footer_rights_sect2">
          <span className="flex-item">
            <a
              // href="https://cardanocrocsclub.com/whitepaper.html?v2"
              // href="../../pdf/Whitepaper.pdf"
              href={WhitePaperPDF}
              target="_blank"
              rel="noreferrer"
            >
              Whitepaper
            </a>
          </span>
          <span className="flex-item">Privacy Policy</span>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
