import React from "react";
import "./index.scss";
import LogoImg from "../../assets/logo.svg";
import TwitterIcon from "../../assets/twitter_link.svg";
import DiscordIcon from "../../assets/discord_link.svg";

const Menu = ({ isOpen, setIsMenuOpen }) => {
  return (
    <div className="menu">
      <div className={isOpen ? "backdrop backdrop_open" : "backdrop"}></div>
      <div
        className={
          isOpen ? "menu_container menu_container_shown" : "menu_container"
        }
      >
        <div className="menu_content">
          <a href="/">
            <img className="menu_logo" src={LogoImg} alt="logo" />
          </a>
          <ul>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#token">Token</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#nfts">nft collections</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#swamplands">swamplands</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#player-pass">$C4 Player Pass</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#crocpad">Crocpad</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#investments">$C4 INVESTMENTS</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#team">team</a>
            </li>
          </ul>
          <div className="menu_content_links">
            <a
              href="https://twitter.com/CardanoCrocClub"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TwitterIcon} alt="twitter" />
            </a>
            <a
              href="https://discord.gg/cardanocrocsclub"
              target="_blank"
              rel="noreferrer"
            >
              <img src={DiscordIcon} alt="discord" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Menu;
