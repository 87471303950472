import "./index.scss";

import React, { useEffect, useRef } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import RectBorders from "../../toolkits/RectBorders";
import RadioactiveBox from "../../toolkits/RadioactiveBox";

import SeanCrocImg from "../../assets/team/founders/sean_croc.png";
import AlexCrocImg from "../../assets/team/founders/alex_croc.png";

import {
  getRem,
  isDesktopMedia,
  isMobileMedia,
  isTabletMedia,
} from "../../utils";
import TeamCard from "./TeamCard";
import { DevelopersData, ModeratorsData, TeamMembersData } from "./teamData";

const TeamSection = () => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();
  const isDesktop = isDesktopMedia();

  const teamFoundersBoxRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap
      .timeline({ scrollTrigger: { trigger: ".team-founders" } })
      .to(".team-founders__card", {
        delay: 0.5,
        duration: 0.8,
        width: isDesktop ? getRem(1240) : isTablet ? getRem(720) : "",
      })
      .set(".team-founders__content", {
        duration: 0.1,
        display: "block",
        width: isDesktop ? getRem(800) : isTablet ? getRem(380) : "100%",
      })
      .to(".team-founders__content", {
        delay: 0.7,
        opacity: 1,
        duration: 1,
      });

    gsap
      .timeline({ scrollTrigger: { trigger: ".team-members" } })
      .to(".team-members__box", {
        transform: "translate(0, 0)",
        delay: 0.9,
        duration: 2,
      });

    if (!isMobile) {
      gsap
        .timeline({ scrollTrigger: { trigger: ".team-moderators" } })
        .to(".team-moderators__box .team-members__card", {
          transform: "translate(0, 0)",
          duration: 2,
          delay: 1,
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="team-section">
      <div className="team-founders flex-item">
        <h2>FOUNDERS</h2>
        <div className="team-founders__box" ref={teamFoundersBoxRef}>
          <div className="team-founders__card card-1 flex-item">
            <div className="team-section-img">
              <div className="team-section-img__box">
                <RectBorders />
                <RadioactiveBox />
                <img src={SeanCrocImg} alt="croc of founder Sean" />
              </div>
            </div>

            <div className="team-founders__content">
              <h3>Sean</h3>
              <div className="team-founders__short-bio">
                Founder, Crypto Youtuber and F1 enthusiast
              </div>
              <span>
                Sean Benson is better known for his YouTube Channel (Sean
                Benson), in which he talks about everything crypto. His audience
                largely revolves around the cryptocurrency Cardano (ADA), and
                the ecosystem that's being built on-top of it.
              </span>
              <span>
                Sean founded the Cardano Crocs Club NFT project in an effort to
                showcase the Cardano blockchain and how NFTs could positively
                tie into peoples' lives in the future. If Sean is not watching
                F1, you can find him playing poker on C4poker.io, or learning
                how to code to contribute to the Cardano and greater
                crypto-ecosystem.
              </span>
            </div>
          </div>

          <div className="team-founders__card card-2 flex-item">
            <div className="team-founders__content">
              <h3>Alex</h3>
              <div className="team-founders__short-bio">Co-Founder</div>
              <span>
                Alex owns multiple business in the web2 field, and has years of
                experience working with blockchain technology (Cardano and
                others). Alex joined CCC after the project has shown potential
                in becoming something larger than "just a NFT project", and aims
                to take it to the next level.
              </span>
              <span>
                His knowledge on Decentralized Finance (DeFi), connections with
                other like-minded individuals in the tech industry, and vision
                for what "could be" are massive driving forces for CCC and Croc
                Entertainment. His long-term goal is to integrate the token,
                $C4, with society in a meaningful way, breaking down the
                boundaries between web2, web3, and the "real world".
              </span>
            </div>

            <div className="team-section-img">
              <div className="team-section-img__box">
                <RectBorders />
                <RadioactiveBox />
                <img src={AlexCrocImg} alt="croc of founder Alex" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team-members flex-item">
        <h2>CCC TEAM MEMBERS</h2>
        <div className="team-members__box">
          {TeamMembersData.members.map(({ name, role, desc, img }, index) => (
            <TeamCard
              name={name}
              role={role}
              desc={desc}
              img={img}
              index={index}
              key={name}
            />
          ))}
        </div>
      </div>
      <div className="team-moderators flex-item">
        <h2>CCC MODERATORS</h2>
        <div className="team-moderators__box">
          {ModeratorsData.members.map(({ name, role, desc, img }, index) => (
            <TeamCard
              name={name}
              role={role}
              desc={desc}
              img={img}
              index={index}
              key={name}
            />
          ))}
        </div>
      </div>
      <div className="team-developers flex-item">
        <h2>CCC DEVELOPERS</h2>
        <div className="team-developers__box">
          {DevelopersData.members.map(({ name, role, desc, img }, index) => (
            <TeamCard
              name={name}
              role={role}
              desc={desc}
              img={img}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
