import "./index.scss"

import React from "react"


const Radioactive = ({className}) => (
    <div className={`radioactive ${className ? className : ""}`}>
      <svg viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.64 0.865234L26 17.8652H0L9.36 0.865234H16.64Z" fill="#717171"/>
      </svg>
      <svg viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.64 0.865234L26 17.8652H0L9.36 0.865234H16.64Z" fill="#717171"/>
      </svg>
      <svg viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.64 0.865234L26 17.8652H0L9.36 0.865234H16.64Z" fill="#717171"/>
      </svg>
    </div>
)

export default Radioactive
