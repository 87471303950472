import "./index.scss";

import React, { useEffect, useRef, useState } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import ArrowTopImg from "../../assets/arrow_top.svg";
import CardanoAvatar from "../../assets/avatars/croc_1.png";
import RadioactiveAvatar from "../../assets/avatars/croc_2.png";
import BabyAvatar from "../../assets/avatars/croc_3.png";
import PlayerPassAvatar from "../../assets/avatars/croc_4.png";
import ArrowImg from "../../toolkits/ScrollArrow";

import CardanoSmallCardImg1 from "../../assets/nft_collections/cardano/croc-1.png";
import CardanoSmallCardImg2 from "../../assets/nft_collections/cardano/croc-2.png";
import CardanoSmallCardImg3 from "../../assets/nft_collections/cardano/croc-3.png";
import CardanoSmallCardImg4 from "../../assets/nft_collections/cardano/croc-4.png";
import RadioactiveSmallCardImg1 from "../../assets/nft_collections/radioactive/croc-1.png";
import RadioactiveSmallCardImg2 from "../../assets/nft_collections/radioactive/croc-2.png";
import RadioactiveSmallCardImg3 from "../../assets/nft_collections/radioactive/croc-3.png";
import RadioactiveSmallCardImg4 from "../../assets/nft_collections/radioactive/croc-4.png";
import BabySmallCardImg1 from "../../assets/nft_collections/baby/croc-1.png";
import BabySmallCardImg2 from "../../assets/nft_collections/baby/croc-2.png";
import BabySmallCardImg3 from "../../assets/nft_collections/baby/croc-3.png";
import BabySmallCardImg4 from "../../assets/nft_collections/baby/croc-4.png";
import PlayerPassSmallCardImg from "../../assets/nft_collections/player_pass.png";

import { getRem, isDesktopMedia, isTabletMedia } from "../../utils";
import SmallButton from "../../toolkits/SmallButton";
import RectBorders from "../../toolkits/RectBorders";
import RadioactiveBox from "../../toolkits/RadioactiveBox";

const NFTSection = () => {
  const isDesktop = isDesktopMedia();
  const isTablet = isTabletMedia();
  const isDesktopAnim = isDesktop ? true : isTablet ? false : null;

  const [buyCrocsAnim, setBuyCrocsAnim] = useState({
    show: false,
    progress: false,
  });

  const cardanoCardRef = useRef(null);
  const radioactiveCardRef = useRef(null);
  const babyCardRef = useRef(null);
  const playerCardRef = useRef(null);

  const [cardsAnimStates, setCardAnimStates] = useState({
    cardano: null,
    radioactive: null,
    baby: null,
    player: null,
  });

  const toggleBuyCrocs = () => {
    setBuyCrocsAnim((state) => ({ ...state, progress: true }));

    if (!buyCrocsAnim.show) {
      const showBuyCrocsProps = {
        display: "flex",
        opacity: 1,
        duration: 1,
      };

      gsap
        .timeline()
        .to(".nft_buy h3 img", { scaleY: 1, duration: 1 })
        .to(".nft_buy_step_1", showBuyCrocsProps, "<")
        .to(".nft_buy_step_2", showBuyCrocsProps)
        .to(".nft_buy_step_3", showBuyCrocsProps)
        .to(".nft_buy_step_4", showBuyCrocsProps)
        .then(() => setBuyCrocsAnim({ show: true, progress: false }));
    } else {
      const hideBuyCrocsProps = {
        display: "none",
        opacity: 0,
        duration: 1,
      };

      gsap
        .timeline()
        .to(".nft_buy h3 img", { scaleY: -1, duration: 1 })
        .to(".nft_buy_step_1", hideBuyCrocsProps, "<")
        .to(".nft_buy_step_2", hideBuyCrocsProps, "<")
        .to(".nft_buy_step_3", hideBuyCrocsProps, "<")
        .to(".nft_buy_step_4", hideBuyCrocsProps, "<")
        .then(() => setBuyCrocsAnim({ show: false, progress: false }));
    }
  };

  const isAllowedHide = () =>
    !Object.values(cardsAnimStates).some((x) => x === "show");

  const isAllowedShow = () => {
    const isCardanoInProgress =
      cardanoCardRef.current.classList.contains("hidden");

    return (
      !Object.values(cardsAnimStates).some((x) => x === "hide") &&
      !isCardanoInProgress
    );
  };

  const hideSmallCardsAnimation = (className, duration) => {
    return gsap.timeline().to(`${className} .nft_small-card`, {
      top: getRem(200),
      right: getRem(20),
      transform: "rotate(0)",
      duration: duration ?? 1,
      display: "none",
    });
  };

  const showCardanoAnimation = () => {
    gsap
      .timeline()
      .set(".nft_cardano .nft_small-card", {
        display: "block",
      })
      .to(".nft_cardano .card-1", {
        top: isDesktopAnim ? getRem(-137) : getRem(-77),
        right: isDesktopAnim ? getRem(557) : getRem(280),
        transform: isDesktopAnim
          ? "rotate(-60deg)"
          : "matrix(0.45, -0.89, 0.89, 0.45, 0, 0)",
        duration: 1,
      })
      .to(
        ".nft_cardano .card-2",
        {
          top: isDesktopAnim ? getRem(-156) : getRem(-78),
          right: isDesktopAnim ? getRem(382) : getRem(200),
          transform: isDesktopAnim
            ? "rotate(30deg)"
            : "matrix(0.89, 0.45, -0.45, 0.89, 0, 0)",
          duration: 1,
        },
        "<"
      )
      .to(
        ".nft_cardano .card-3",
        {
          top: isDesktopAnim ? getRem(-175) : getRem(-90),
          right: isDesktopAnim ? getRem(199) : getRem(110),
          transform: isDesktopAnim ? "rotate(-15deg)" : "rotate(-18deg)",
          duration: 1,
        },
        "<"
      )
      .to(
        ".nft_cardano .card-4",
        {
          top: isDesktopAnim ? getRem(-175) : getRem(-91),
          right: isDesktopAnim ? getRem(10) : getRem(14),
          transform: isDesktopAnim
            ? "rotate(45deg)"
            : "matrix(0.74, 0.67, -0.67, 0.74, 0, 0)",
          duration: 1,
        },
        "<"
      );
  };

  const toggleCardano = () => {
    const isHiddenCard = cardanoCardRef.current.classList.contains("");

    if (!isHiddenCard) {
      toggleRadioactive("hide");
    }
  };

  const showRadioactiveAnimation = () => {
    gsap
      .timeline()
      .set(".nft_radioactive .nft_small-card", {
        display: "block",
      })
      .to(".nft_radioactive .card-1", {
        top: isDesktopAnim ? getRem(-172) : getRem(-95),
        right: isDesktopAnim ? getRem(593) : getRem(298),
        transform: isDesktopAnim ? "rotate(30deg)" : "rotate(33deg)",
        duration: 1,
      })
      .to(
        ".nft_radioactive .card-2",
        {
          top: isDesktopAnim ? getRem(-168) : getRem(-90),
          right: isDesktopAnim ? getRem(412) : getRem(205),
          transform: isDesktopAnim ? "rotate(15deg)" : "rotate(18deg)",
          duration: 1,
        },
        "<"
      )
      .to(
        ".nft_radioactive .card-3",
        {
          top: isDesktopAnim ? getRem(-145) : getRem(-80),
          right: isDesktopAnim ? getRem(210) : getRem(105),
          transform: isDesktopAnim ? "rotate(-30deg)" : "rotate(-27deg)",
          duration: 1,
        },
        "<"
      )
      .to(
        ".nft_radioactive .card-4",
        {
          top: isDesktopAnim ? getRem(-187) : getRem(-97),
          right: isDesktopAnim ? getRem(40) : getRem(15),
          transform: isDesktopAnim ? "rotate(45deg)" : "rotate(48deg)",
          duration: 1,
        },
        "<"
      );
  };

  const toggleRadioactive = (action) => {
    const isHiddenCard =
      radioactiveCardRef.current.classList.contains("hidden");

    if (
      (isHiddenCard && action === "hide") ||
      (!isHiddenCard && action === "show")
    ) {
      return;
    }

    if (isHiddenCard) {
      if (isAllowedShow()) {
        setCardAnimStates((state) => ({ ...state, radioactive: "show" }));

        gsap
          .timeline()
          .to(
            ".nft_radioactive",
            {
              transform: "none",
              duration: 0.7,
            },
            "<"
          )
          .then(() => {
            radioactiveCardRef.current.classList.remove("hidden");
            setCardAnimStates((state) => ({ ...state, radioactive: null }));
          });
      }
    } else {
      if (isAllowedHide()) {
        toggleBaby("hide");

        if (action !== "base") {
          setCardAnimStates((state) => ({ ...state, radioactive: "hide" }));
          hideSmallCardsAnimation(".nft_radioactive");

          gsap
            .timeline()
            .to(".nft_radioactive", {
              transform: `translate(${getRem(875)}, ${getRem(
                230
              )}) rotate(-30deg)`,
              duration: 0.7,
            })
            .then(() => {
              radioactiveCardRef.current.classList.add("hidden");
              setCardAnimStates((state) => ({ ...state, radioactive: null }));
            });
        } else {
          hideSmallCardsAnimation(".nft_radioactive", 0.5).then(() => {
            showRadioactiveAnimation();
          });
        }
      }
    }
  };

  const showBabyAnimation = () => {
    gsap
      .timeline()
      .set(".nft_baby .nft_small-card", {
        display: "block",
      })
      .to(".nft_baby .card-1", {
        top: isDesktopAnim ? getRem(-170) : getRem(-88),
        right: isDesktopAnim ? getRem(538) : getRem(265),
        transform: isDesktopAnim ? "rotate(-15deg)" : "rotate(-18deg)",
        duration: 1,
      })
      .to(
        ".nft_baby .card-2",
        {
          top: isDesktopAnim ? getRem(-171) : getRem(-93),
          right: isDesktopAnim ? getRem(347) : getRem(171),
          transform: isDesktopAnim ? "rotate(15deg)" : "rotate(12deg)",
          duration: 1,
        },
        "<"
      )
      .to(
        ".nft_baby .card-3",
        {
          top: isDesktopAnim ? getRem(-166) : getRem(-82),
          right: isDesktopAnim ? getRem(208) : getRem(102),
          transform: isDesktopAnim ? "rotate(-60deg)" : "rotate(-63deg)",
          duration: 1,
        },
        "<"
      )
      .to(
        ".nft_baby .card-4",
        {
          top: isDesktopAnim ? getRem(-159) : getRem(-82),
          right: isDesktopAnim ? getRem(37) : getRem(15),
          transform: isDesktopAnim ? "rotate(60deg)" : "rotate(57deg)",
          duration: 1,
        },
        "<"
      );
  };

  const toggleBaby = (action) => {
    const isHiddenCard = babyCardRef.current.classList.contains("hidden");

    if (
      (isHiddenCard && action === "hide") ||
      (!isHiddenCard && action === "show")
    ) {
      return;
    }

    if (isHiddenCard) {
      if (isAllowedShow()) {
        toggleRadioactive("show");
        setCardAnimStates((state) => ({ ...state, baby: "show" }));

        gsap
          .timeline()
          .to(".nft_baby", {
            transform: "none",
            duration: 0.7,
          })
          .then(() => {
            babyCardRef.current.classList.remove("hidden");
            setCardAnimStates((state) => ({ ...state, baby: null }));
          });
      }
    } else {
      if (isAllowedHide()) {
        togglePlayer("hide");

        if (action !== "base") {
          setCardAnimStates((state) => ({ ...state, baby: "hide" }));
          hideSmallCardsAnimation(".nft_baby");

          gsap
            .timeline()
            .to(".nft_baby", {
              transform: `translate(${getRem(880)}, ${getRem(
                210
              )}) rotate(-30deg)`,
              duration: 0.7,
            })
            .then(() => {
              babyCardRef.current.classList.add("hidden");
              setCardAnimStates((state) => ({ ...state, baby: null }));
            });
        } else {
          hideSmallCardsAnimation(".nft_baby", 0.5).then(() => {
            showBabyAnimation();
          });
        }
      }
    }
  };

  const showPlayerAnimation = () => {
    gsap
      .timeline()
      .set(".nft_player .nft_small-card", {
        display: "block",
      })
      .to(".nft_player .card-1", {
        transform: isDesktopAnim ? "rotate(-15deg)" : "rotate(-12deg)",
        top: isDesktopAnim ? getRem(-186) : getRem(-120),
        right: isDesktopAnim ? getRem(270) : getRem(173),
        duration: 1,
      })
      .to(
        ".nft_player .card-2",
        {
          transform: isDesktopAnim ? "none" : "rotate(3deg)",
          right: isDesktopAnim ? getRem(170) : getRem(85),
          top: isDesktopAnim ? getRem(-208) : getRem(-139),
          duration: 1,
        },
        "<"
      )
      .to(
        ".nft_player .card-3",
        {
          transform: isDesktopAnim ? "rotate(15deg)" : "rotate(18deg)",
          top: isDesktopAnim ? getRem(-186) : getRem(-124),
          right: isDesktopAnim ? getRem(70) : getRem(6),
          duration: 1,
        },
        "<"
      );
  };

  const togglePlayer = (action) => {
    const isHiddenCard = playerCardRef.current.classList.contains("hidden");

    if (
      (isHiddenCard && action === "hide") ||
      (!isHiddenCard && action === "show")
    ) {
      return;
    }

    if (isHiddenCard) {
      if (isAllowedShow()) {
        toggleBaby("show");
        setCardAnimStates((state) => ({ ...state, player: "show" }));

        gsap
          .timeline()
          .to(".nft_player", {
            transform: "none",
            duration: 0.7,
          })
          .then(() => {
            playerCardRef.current.classList.remove("hidden");
            setCardAnimStates((state) => ({ ...state, player: null }));
          });
      }
    } else {
      if (isAllowedHide()) {
        setCardAnimStates((state) => ({ ...state, player: "hide" }));
        hideSmallCardsAnimation(".nft_player");

        gsap
          .timeline()
          .to(".nft_player", {
            transform: `translate(${getRem(885)}, ${getRem(
              190
            )}) rotate(-30deg)`,
            duration: 0.7,
          })
          .then(() => {
            playerCardRef.current.classList.add("hidden");
            setCardAnimStates((state) => ({ ...state, player: null }));
          });
      }
    }
  };

  useEffect(() => {
    const nftBuyTl = gsap.timeline().to(".nft_buy", {
      delay: 2.5,
      onComplete: () => toggleBuyCrocs(),
    });

    if (
      isDesktop &&
      radioactiveCardRef.current?.classList.contains("hidden") &&
      babyCardRef.current?.classList.contains("hidden") &&
      playerCardRef.current?.classList.contains("hidden")
    ) {
      setCardAnimStates((state) => ({ ...state, cardano: "show" }));

      const tl = gsap.timeline().to(
        ".nft_cardano",
        {
          transform: "none",
          duration: 0.7,
          delay: 1,
        },
        "<"
      );

      tl.then(() => {
        cardanoCardRef.current?.classList.remove("hidden");
        setCardAnimStates((state) => ({ ...state, cardano: null }));
        showCardanoAnimation();
      });

      return () => {
        nftBuyTl.clear();
        tl.clear();
      };
    }

    return () => nftBuyTl.clear();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (isDesktop) {
      const isCardanoVisible =
        !cardanoCardRef.current?.classList.contains("hidden");
      const isRadioactiveVisible =
        !radioactiveCardRef.current?.classList.contains("hidden");
      const isBabyVisible = !babyCardRef.current?.classList.contains("hidden");
      const isPlayerVisible =
        !playerCardRef.current?.classList.contains("hidden");

      if (isPlayerVisible) {
        hideSmallCardsAnimation(".nft_cardano");
        hideSmallCardsAnimation(".nft_radioactive");
        hideSmallCardsAnimation(".nft_baby");
        showPlayerAnimation();
      } else if (isBabyVisible) {
        hideSmallCardsAnimation(".nft_cardano");
        hideSmallCardsAnimation(".nft_radioactive");
        showBabyAnimation();
      } else if (isRadioactiveVisible) {
        hideSmallCardsAnimation(".nft_cardano");
        showRadioactiveAnimation();
      } else if (!isBabyVisible && !isRadioactiveVisible && isCardanoVisible) {
        showCardanoAnimation();
      }
    } else if (isDesktopAnim !== null) {
      const tl1 = gsap
        .timeline({ scrollTrigger: { trigger: ".nft_cardano" } })
        .to(".nft_cardano", {
          duration: 1,
        });
      tl1.then(() => {
        showCardanoAnimation();
      });

      const tl2 = gsap
        .timeline({ scrollTrigger: { trigger: ".nft_radioactive" } })
        .to(".nft_radioactive", {
          duration: 1,
        });
      tl2.then(() => {
        showRadioactiveAnimation();
      });

      const tl3 = gsap
        .timeline({ scrollTrigger: { trigger: ".nft_baby" } })
        .to(".nft_baby", {
          duration: 1,
        });
      tl3.then(() => {
        showBabyAnimation();
      });

      const tl4 = gsap
        .timeline({ scrollTrigger: { trigger: ".nft_player" } })
        .to(".nft_player", {
          duration: 1,
        });
      tl4.then(() => {
        showPlayerAnimation();
      });

      return () => {
        tl1.clear();
        tl2.clear();
        tl3.clear();
        tl4.clear();
      };
    }
    // eslint-disable-next-line
  }, [
    cardanoCardRef.current?.classList.length,
    radioactiveCardRef.current?.classList.length,
    babyCardRef.current?.classList.length,
    playerCardRef.current?.classList.length,
  ]);

  return (
    <div className="nft">
      <div className="nft_collections">
        {/* CARDANO CROCS CLUB CARD */}
        <div
          ref={cardanoCardRef}
          className="flex-item nft_card black nft_cardano hidden"
          onClick={() => isDesktop && toggleCardano("base")}
        >
          <img
            className="nft_small-card card-1"
            src={CardanoSmallCardImg1}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-2"
            src={CardanoSmallCardImg2}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-3"
            src={CardanoSmallCardImg3}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-4"
            src={CardanoSmallCardImg4}
            alt="nft small card"
          />

          <div className="nft_card_wrapper">
            <h3 className="nft_card_title">CARDANO CROCS CLUB</h3>
            <div className="nft_card_box">
              <div className="flex-item nft_card_content">
                <div className="flex-item">
                  The Cardano Crocs Club (CCC) is a collection of 7700 2D Croc
                  NFTs. The collection size started out at 10,000, but through a
                  community burning event, 4600 were burned and 2300 Crocs with
                  new traits were minted. While their primary mission is to look
                  cool as a PFP, their secondary mission is to earn as much
                  daily $C4 as they can!
                  <br />
                  <br />
                  If you are looking for "utility" and believe in Cardano, Crocs
                  are a must-have in every portfolio. So what are you waiting
                  for? Get yourself a Croc today!
                </div>
                <div className="flex-item">
                  <SmallButton>
                    <a
                      href="https://www.jpg.store/collection/cardanocrocsclub"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JPG.STORE
                    </a>
                  </SmallButton>
                </div>
              </div>
              <div className="flex-item nft_card_img">
                <RadioactiveBox />
                <RectBorders />

                <img src={CardanoAvatar} alt="croc avatar" />
              </div>
            </div>
          </div>
        </div>

        {/* RADIOACTIVE CROCS CLUB CARD */}
        <div
          ref={radioactiveCardRef}
          className="flex-item nft_card green nft_radioactive hidden"
          onClick={() => isDesktop && toggleRadioactive("base")}
        >
          <img
            className="nft_small-card card-1"
            src={RadioactiveSmallCardImg1}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-2"
            src={RadioactiveSmallCardImg2}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-3"
            src={RadioactiveSmallCardImg3}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-4"
            src={RadioactiveSmallCardImg4}
            alt="nft small card"
          />

          <div className="nft_card_wrapper">
            <h3 className="nft_card_title">RADIOACTIVE CROCS CLUB</h3>
            <div className="nft_card_box">
              <div className="flex-item nft_card_content">
                <div className="flex-item">
                  The Radioactive Crocs Club (RCC) is the second collection,
                  consisting of 9975 3D Crocs. It was created to capitalize on
                  the "metaverse" aspect, for when projects launch 3D metaverses
                  and avatars are necessary. RCCs generate half the daily
                  staking rewards as their CCC counterparts (based on rarity
                  ranks).
                </div>
                <div className="flex-item buttonsBox">
                  <SmallButton blackMode>
                    <a
                      href="https://www.jpg.store/collection/radioactivecrocsclub"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JPG.STORE
                    </a>
                  </SmallButton>
                  <SmallButton blackMode>
                    <a
                      href="https://radioactivecrocsclub.com/members.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website
                    </a>
                  </SmallButton>
                </div>
              </div>
              <div className="flex-item nft_card_img">
                <RadioactiveBox />
                <RectBorders />

                <img src={RadioactiveAvatar} alt="croc avatar" />
              </div>
            </div>
          </div>
        </div>

        {/* BABY CROCS CLUB CARD */}
        <div
          ref={babyCardRef}
          className="flex-item nft_card black nft_baby hidden"
          onClick={() => isDesktop && toggleBaby("base")}
        >
          <img
            className="nft_small-card card-1"
            src={BabySmallCardImg1}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-2"
            src={BabySmallCardImg2}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-3"
            src={BabySmallCardImg3}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-4"
            src={BabySmallCardImg4}
            alt="nft small card"
          />

          <div className="nft_card_wrapper">
            <h3 className="nft_card_title">BABY CROCS CLUB</h3>
            <div className="nft_card_box">
              <div className="flex-item nft_card_content">
                <div className="flex-item">
                  The Baby Crocs Club (BCC) is the third collection and was
                  created through a breeding game. Players were able to choose
                  any 2 Crocs (CCC or RCC) and "breed" them. After a period of 8
                  weeks, a Baby Croc was created, which inherits the traits of
                  its parents. BCC consists of 5081 pixel-art Crocs, each
                  providing a staking multiplier for the CCC and RCC
                  collections.
                </div>
                <div className="flex-item">
                  <SmallButton>
                    <a
                      href="https://www.jpg.store/collection/babycrocsclub"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JPG.STORE
                    </a>
                  </SmallButton>
                </div>
              </div>
              <div className="flex-item nft_card_img">
                <RadioactiveBox />
                <RectBorders />

                <img src={BabyAvatar} alt="croc avatar" />
              </div>
            </div>
          </div>
        </div>

        {/* PLAYER PASS CARD */}
        <div
          ref={playerCardRef}
          className="flex-item nft_card green nft_player hidden"
          onClick={() => isDesktop && togglePlayer("base")}
        >
          <img
            className="nft_small-card card-1"
            src={PlayerPassSmallCardImg}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-2"
            src={PlayerPassSmallCardImg}
            alt="nft small card"
          />
          <img
            className="nft_small-card card-3"
            src={PlayerPassSmallCardImg}
            alt="nft small card"
          />

          <div className="nft_card_wrapper">
            <h3 className="nft_card_title">$C4 PLAYER PASS</h3>
            <div className="nft_card_box">
              <div className="flex-item nft_card_content">
                <div className="flex-item">
                  And as a cherry on top — the $C4 Player Pass — an exclusive
                  collection of 750 NFTs that rewards holders with a share of
                  the poker platform's profits, and a portion of the Swamplands
                  transaction activities. Each pass needs to be staked alongside
                  8 Crocs to be active.
                </div>
                <div className="flex-item">
                  <SmallButton blackMode>
                    <a
                      href="https://www.jpg.store/collection/c4playerpass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JPG.STORE
                    </a>
                  </SmallButton>
                </div>
              </div>
              <div className="flex-item nft_card_img">
                <RadioactiveBox />
                <RectBorders />

                <img src={PlayerPassAvatar} alt="croc avatar" />
              </div>
            </div>
          </div>
        </div>
        <div className="nft_collections_arrowBlock">
          <p>
            Click on collection card
            <br /> for more information
          </p>
          <div className="arrowWrapper">
            <ArrowImg className="arrow" />
          </div>
        </div>
      </div>

      <div className="nft_buy">
        <h3 onClick={() => !buyCrocsAnim.progress && toggleBuyCrocs()}>
          <span>HOW TO BUY CROCS</span>
          <img src={ArrowTopImg} alt="arrow" />
        </h3>
        <div className="nft_buy_content">
          <div className="flex-item nft_buy_item nft_buy_step_1">
            <div className="flex-item nft_buy_item_step">Step 1</div>
            <div className="flex-item nft_buy_item_text">
              You need to create a Cardano{" "}
              <a href="https://namiwallet.io/">wallet</a>.
            </div>
          </div>
          <div className="flex-item nft_buy_item nft_buy_step_2">
            <div className="flex-item nft_buy_item_step">Step 2</div>
            <div className="flex-item nft_buy_item_text">
              Add ADA to your wallet. You can get ADA from an exchange.
            </div>
          </div>
          <div className="flex-item nft_buy_item nft_buy_step_3">
            <div className="flex-item nft_buy_item_step">Step 3</div>
            <div className="flex-item nft_buy_item_text">
              <div>
                #3. Go to a Cardano NFT marketplace with Crocs. Direct links{" "}
                <a
                  href="https://www.jpg.store/"
                  target="_blank"
                  rel="noreferrer"
                >
                  jpg.store
                </a>{" "}
                , one of the marketplaces, are below:
              </div>
              <div className="nft_buy_item_links">
                <div className="flex-item">
                  <span className="nft_buy_item_img" />
                  Original Crocs:{" "}
                  <a
                    href="https://www.jpg.store/collection/cardanocrocsclub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.jpg.store/collection/cardanocrocsclub
                  </a>
                </div>
                <div className="flex-item">
                  <span className="nft_buy_item_img" />
                  Radioactive Crocs:{" "}
                  <a
                    href="https://www.jpg.store/collection/radioactivecrocsclub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.jpg.store/collection/radioactivecrocsclub
                  </a>
                </div>
                <div className="flex-item">
                  <span className="nft_buy_item_img" />
                  Baby Crocs:{" "}
                  <a
                    href="https://www.jpg.store/collection/babycrocsclub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.jpg.store/collection/babycrocsclub
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-item nft_buy_item nft_buy_step_4">
            <div className="flex-item nft_buy_item_step">Step 4</div>
            <div className="flex-item nft_buy_item_text">
              Choose the Croc(s) that suits you perfectly!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTSection;
