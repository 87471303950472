import "./index.scss";

import React, { useEffect } from "react";

import { isDesktopMedia, isMobileMedia } from "../../utils";

import Radioactive from "../Radioactive";

import { useElementsOnScreen } from "../../components/hooks";

const ScrollHeader = ({ title, containerClassName }) => {
  const isDesktop = isDesktopMedia();
  const isMobile = isMobileMedia();

  const [containerRef, isVisible] = useElementsOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });

  const Header = () => (
    <div className="scroll-header" id={`scroll-header_1`}>
      {isDesktop && (
        <>
          <Radioactive />
          <span>CARDANO CROCS CLUB</span>
        </>
      )}
      {(title !== "$C4 STACKING REWARDS" || !isMobile) && (
        <Radioactive className="scroll-header_token-img" />
      )}
      <span className="scroll-header_token">{title}</span>
      <Radioactive className="scroll-header_token-img" />
      <span>CARDANO CROCS CLUB</span>
      <Radioactive />
      <span>CARDANO CROCS CLUB</span>
      <Radioactive />
      <span>CARDANO CROCS CLUB</span>
    </div>
  );

  useEffect(() => {
    /*gsap.registerPlugin(ScrollTrigger)

        const animEffect = "power3.out"

        gsap.timeline({
             scrollTrigger: {trigger: `.${containerClassName}`}
        })
        .to([`.${containerClassName} .scroll-header_token-img svg`, `.${containerClassName} .scroll-header_token-img path`], {
            filter: `drop-shadow(0 0 ${getRem(28.8462)} rgba(165, 250, 0, 0.5))`,
            fill: "#A5FA00",
            duration: 2,
            delay: 0.2,
            ease: animEffect,
        })
        .to(`.${containerClassName} #scroll-header_1`, {
            left: "2%",
            duration: 3,
            ease: animEffect,
        }, "<")
        .to(`.${containerClassName} #scroll-header_2`, {
            right: "98%",
            duration: 3,
            ease: animEffect,
        }, "<")
        .to(`.${containerClassName} .scroll-header_token`, {
            color: "#A5FA00",
            fontSize: isDesktop ? getRem(64) : isTablet ? getRem(48) : getRem(32),
            lineHeight: isDesktop ? getRem(77) : isTablet ? getRem(58) : getRem(38),
            margin: `0 ${isDesktop ? getRem(40) : isTablet ? getRem(30) : getRem(15)}`,
            delay: 0.3,
            duration: 1,
            ease: animEffect,
        }, "<")
        .to(`.${containerClassName} .scroll-header_token-img`, {
            width: isDesktop ? getRem(52) : isTablet ? getRem(40) : getRem(30),
            height: isDesktop ? getRem(52) : isTablet ? getRem(40) : getRem(30),
            duration: 1,
            ease: animEffect,
        }, "<")*/
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`scroll-header_container ${containerClassName}`}
      ref={containerRef}
    >
      {isVisible && (
        <div className="scroll-header_wrapper">
          <Header />
          <div className="scroll-header" id="scroll-header_2">
            <Radioactive />
            <span>CARDANO CROCS CLUB</span>
            <Radioactive />
            <span>CARDANO CROCS CLUB</span>
            <Radioactive />
            <span>CARDANO CROCS CLUB</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScrollHeader;
