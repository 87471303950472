import "./index.scss";

import React from "react";

import Croc1Img from "../../assets/crocodiles/croc_1.png";
import Croc2Img from "../../assets/crocodiles/croc_2.png";

import SmallButton from "../../toolkits/SmallButton";
import ScrollArrow from "../../toolkits/ScrollArrow";


const CommunitySection = () => (
    <div className="community">
        <div className="community__content">
        <img src={Croc2Img} alt="crocodile" />
        <img src={Croc1Img} alt="crocodile" />
        <div className="flex-item community__info">
            Do you want to be the first to know all the news, insights and important
            information? <span>Join our community! </span>
            We always have something interesting and useful.
        </div>
        <ScrollArrow className="flex-item"/>
        <SmallButton
            className="flex-item"
            onClick={() =>
            window.open("https://discord.com/invite/cardanocrocsclub")
            }
        >
            {"JOIN NOW"}
        </SmallButton>
        </div>
    </div>
);

export default CommunitySection;
