import React, { useState } from "react";
import "./index.scss";
import RectBorders from "../../toolkits/RectBorders";
import RadioactiveBox from "../../toolkits/RadioactiveBox";
import ArrowTopImg from "../../assets/arrow_top.svg";
import { isDesktopMedia } from "../../utils";

const TeamCard = ({ name, role, desc, img, index }) => {
  const [infoShown, setInfoShown] = useState(false);
  const isDesktop = isDesktopMedia();

  const handleShowInfo = () => {
    if (!isDesktop) {
      setInfoShown(!infoShown);
    }
  };

  return (
    <div
      className={
        role.toLowerCase().includes("moderator")
          ? `flex-item team-members__card mod-${index + 1}`
          : "flex-item team-members__card"
      }
    >
      <div className="team-section-img">
        <div className="team-section-img__box">
          <RectBorders />
          <RadioactiveBox />
          <img src={img} alt="croc of member" />
        </div>
      </div>

      <div className="team-members__name">{name}</div>
      <div className="team-members__short-bio">{role}</div>
      {desc && (
        <>
          {!isDesktop && infoShown && (
            <div className="team-members__content">{desc}</div>
          )}
          {isDesktop && <div className="team-members__content">{desc}</div>}
          <div
            className={
              infoShown
                ? "team-members__arrow team-members__arrow_open"
                : "team-members__arrow"
            }
            onClick={handleShowInfo}
          >
            <img src={ArrowTopImg} alt="arrow" />
          </div>
        </>
      )}
    </div>
  );
};

export default TeamCard;
