import "./styles/base.scss";
import "./App.scss";

import React, { useEffect } from "react";

import TokenSection from "./components/TokenSection";
import AppHeader from "./components/AppHeader";
import WelcomeSection from "./components/WelcomeSection";
import SwamplandsSection from "./components/SwamplandsSection";
import StackingRewardSection from "./components/StackingRewardSection";
import NFTSection from "./components/NFTSection";
import CrocpadSection from "./components/CrocpadSection";
import PlayerPassSection from "./components/PlayerPassSection";
import AppFooter from "./components/AppFooter";
import C4InvestmentsSection from "./components/C4InvestmentsSection";
import TeamSection from "./components/TeamSection";
import CommunitySection from "./components/CommunitySection";

import ScrollHeader from "./toolkits/ScrollHeader";

import { isDesktopMedia } from "./utils";
import { useElementsOnScreen } from "./components/hooks";

const App = () => {
  const isDesktop = isDesktopMedia();

  const [nftContainerRef, isVisibleNft] = useElementsOnScreen();
  const [tokenContainerRef, isVisibleToken] = useElementsOnScreen();
  const [stakingRewardsContainerRef, isVisibleStakingRewards] =
    useElementsOnScreen();
  const [crocpadContainerRef, isVisibleCrocpad] = useElementsOnScreen();
  const [swamplandsContainerRef, isVisibleSwamplands] = useElementsOnScreen();
  const [communityContainerRef, isVisibleCommunity] = useElementsOnScreen();
  const [playerContainerRef, isVisiblePlayer] = useElementsOnScreen();
  const [investContainerRef, isVisibleInvest] = useElementsOnScreen();
  const [teamContainerRef, isVisibleTeam] = useElementsOnScreen();

  useEffect(() => {
    if (isDesktop) {
      document.body.classList.add("forbid-page-scroll");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app">
      <AppHeader />

      <div className="content">
        <WelcomeSection />

        <ScrollHeader title="TOKEN" containerClassName="scroll-header_token" />
        <div className="token-wrapper" id="token" ref={tokenContainerRef}>
          {isVisibleToken && <TokenSection />}
        </div>

        <ScrollHeader
          title="NFT COLLECTIONS"
          containerClassName="scroll-header_nfts"
        />
        <div className="nft-wrapper" id="nfts" ref={nftContainerRef}>
          {isVisibleNft && <NFTSection />}
        </div>

        <ScrollHeader
          title="$C4 STACKING REWARDS"
          containerClassName="scroll-header_staking"
        />
        <div
          className="staking-rewards-wrapper"
          id="stacking-rewards"
          ref={stakingRewardsContainerRef}
        >
          {isVisibleStakingRewards && <StackingRewardSection />}
        </div>

        <ScrollHeader
          title="SWAMPLANDS"
          containerClassName="scroll-header_swamplands"
        />
        <div
          className="swamplands-wrapper"
          id="swamplands"
          ref={swamplandsContainerRef}
        >
          {isVisibleSwamplands && <SwamplandsSection />}
        </div>

        <ScrollHeader
          title="$C4 PLAYER PASS"
          containerClassName="scroll-header_player-pass"
        />
        <div
          className="player-pass-wrapper"
          id="player-pass"
          ref={playerContainerRef}
        >
          {isVisiblePlayer && <PlayerPassSection />}
        </div>

        <div>
          <ScrollHeader
            title="CROCPAD"
            containerClassName="scroll-header_crocpad"
          />
          <div
            className="crocpad-wrapper"
            id="crocpad"
            ref={crocpadContainerRef}
          >
            {isVisibleCrocpad && <CrocpadSection />}
          </div>
        </div>

        <ScrollHeader
          title="$C4 INVESTMENTS"
          containerClassName="scroll-header_investments"
        />
        <div
          className="investments-wrapper"
          id="investments"
          ref={investContainerRef}
        >
          {isVisibleInvest && <C4InvestmentsSection />}
        </div>

        <ScrollHeader title="TEAM" containerClassName="scroll-header_team" />
        <div className="team-wrapper" id="team" ref={teamContainerRef}>
          {isVisibleTeam && <TeamSection />}
        </div>

        <ScrollHeader
          title="COMMUNITY"
          containerClassName="scroll-header_community"
        />
        <div
          className="community-wrapper"
          id="community"
          ref={communityContainerRef}
        >
          {isVisibleCommunity && <CommunitySection />}
        </div>
      </div>

      <div className="footer-wrapper">
        {isVisibleCommunity && <AppFooter />}
      </div>
    </div>
  );
};

export default App;
