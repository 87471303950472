import "./index.scss";

import React, { useEffect } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Land1Img from "../../assets/lands/land_1.png";
import Land2Img from "../../assets/lands/land_2.png";
import Land3Img from "../../assets/lands/land_3.png";
import LandGroupImg1 from "../../assets/lands/swamplands_group/land_1.png";
import LandGroupImg2 from "../../assets/lands/swamplands_group/land_2.png";
import LandGroupImg3 from "../../assets/lands/swamplands_group/land_3.png";
import LandGroupImg4 from "../../assets/lands/swamplands_group/land_4.png";
import LandGroupImg5 from "../../assets/lands/swamplands_group/land_5.png";
import LandGroupImg6 from "../../assets/lands/swamplands_group/land_6.png";
import LandGroupImg7 from "../../assets/lands/swamplands_group/land_7.png";
import LandGroupImg8 from "../../assets/lands/swamplands_group/land_8.png";

import { getRem, isDesktopMedia } from "../../utils";

import SmallButton from "../../toolkits/SmallButton";
import Radioactive from "../../toolkits/Radioactive";
import RectBorders from "../../toolkits/RectBorders";

const landGroupImages = [
  LandGroupImg1,
  LandGroupImg2,
  LandGroupImg3,
  LandGroupImg4,
  LandGroupImg5,
  LandGroupImg6,
  LandGroupImg7,
  LandGroupImg8,
];

const SwamplandsSection = () => {
  const isDesktop = isDesktopMedia();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (isDesktop) {
      const landProps = {
        duration: 0.5,
        opacity: 1,
      };

      gsap
        .timeline({
          scrollTrigger: { trigger: ".swamplands_welcome" },
        })
        .to(".swamplands_welcome", {
          backgroundPositionX: "70%",
          duration: 6,
          delay: 0.5,
          ease: "linear",
        })
        .to(
          ".swamplands_welcome_box",
          {
            opacity: 1,
            duration: 6,
            delay: 0.2,
            ease: "linear",
          },
          "<"
        )
        .to(".swamplands_welcome", {
          backgroundPositionX: "100%",
          duration: 0.3,
          delay: 0.2,
          ease: "linear",
        })
        .to(
          ".swamplands_welcome_box",
          {
            opacity: 0.5,
            transform: "scale(3)",
            filter: `blur(${getRem(5)})`,
            duration: 0.3,
            ease: "linear",
          },
          "<"
        )
        .to(".swamplands_welcome", {
          duration: 1,
          display: "none",
          opacity: 0,
          ease: "linear",
        })
        .to(".swamplands_preview", {
          duration: 1,
          display: "block",
          opacity: 1,
          ease: "linear",
        })
        .to(".swamplands_preview_content img:nth-child(1)", landProps)
        .to(".swamplands_preview_content img:nth-child(2)", landProps)
        .to(".swamplands_preview_content img:nth-child(3)", landProps)
        .to(".swamplands_preview_content img:nth-child(4)", landProps)
        .to(".swamplands_preview_content img:nth-child(5)", landProps)
        .to(".swamplands_preview_content img:nth-child(6)", landProps)
        .to(".swamplands_preview_content img:nth-child(7)", landProps)
        .to(".swamplands_preview_content img:nth-child(8)", landProps)
        .to(".swamplands_preview_content_header", { ...landProps, delay: 0.5 })
        .to(".swamplands_preview_content_info", { ...landProps, delay: 0.5 });
    } else {
      const landProps = {
        duration: 0.5,
        opacity: 1,
      };
      gsap
        .timeline({ scrollTrigger: { trigger: ".swamplands_preview" } })
        .to(".swamplands_preview_content img:nth-child(1)", landProps)
        .to(".swamplands_preview_content img:nth-child(2)", landProps)
        .to(".swamplands_preview_content img:nth-child(3)", landProps)
        .to(".swamplands_preview_content img:nth-child(4)", landProps)
        .to(".swamplands_preview_content img:nth-child(5)", landProps)
        .to(".swamplands_preview_content img:nth-child(6)", landProps)
        .to(".swamplands_preview_content img:nth-child(7)", landProps)
        .to(".swamplands_preview_content img:nth-child(8)", landProps)
        .to(".swamplands_preview_content_header", {
          opacity: 1,
          duration: 0.5,
          delay: 0.5,
        })
        .to(".swamplands_preview_content_info", {
          opacity: 1,
          duration: 0.5,
          delay: 0.5,
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="swamplands">
      <div className="swamplands_preview">
        <div className="swamplands_preview_content">
          {landGroupImages.map((landSrc, i) => (
            <img src={landSrc} alt="land" key={i} />
          ))}
          <span className="swamplands_preview_content_header">
            The “Swamplands” is a new way to build an ecosystem in Web3.
          </span>
          <span className="swamplands_preview_content_info">
            In the Swamplands, CCC members will be able to manage anything that
            happens within the project, such as: income generation, Croc
            Families, Croc Arcade/Casino (bridge between all ecosystems), using
            internal wallets, sending crypto between dashboard users, Cardano
            staking, liquidity providing, and voting.
          </span>
        </div>

        <h3>THE SWAMPLANDS WILL BE DIVIDED INTO 3 AREAS:</h3>
      </div>

      <div className="swamplands_welcome">
        <div className="swamplands_welcome_box">
          <span>Welcome to</span>
          <span>SWAMPLANDS</span>
        </div>
      </div>

      <div className="swamplands_container">
        <div className="flex-item swamplands_card">
          <div className="swamplands_box">
            <Radioactive />
            <Radioactive />
            <Radioactive />
            <Radioactive />
            <RectBorders />

            <img src={Land1Img} alt="land" />
          </div>

          <h3>LIVING LANDS</h3>
          <div className="swamplands_card_total">
            Total Supply: <span>50,000 lands</span>
          </div>
          <div className="swamplands_card_button">
            <button
              onClick={() =>
                window.open("https://www.youtube.com/watch?v=jUke3N7DnHE")
              }
            >
              {"MORE INFO >>"}
            </button>
          </div>
        </div>
        <div className="flex-item swamplands_card">
          <div className="swamplands_box">
            <Radioactive />
            <Radioactive />
            <Radioactive />
            <Radioactive />
            <RectBorders />

            <img src={Land2Img} alt="land" />
          </div>

          <h3>RECREATIONAL LANDS</h3>
          <div className="swamplands_card_total">
            Total Supply: <span>10,000 lands</span>
          </div>
          <div className="swamplands_card_button">
            <button
              onClick={() =>
                window.open("https://www.youtube.com/watch?v=jUke3N7DnHE")
              }
            >
              {"MORE INFO >>"}
            </button>
          </div>
        </div>
        <div className="flex-item swamplands_card">
          <div className="swamplands_box">
            <Radioactive />
            <Radioactive />
            <Radioactive />
            <Radioactive />
            <RectBorders />

            <img src={Land3Img} alt="land" />
          </div>

          <div className="swamplands_card_header">COMMERCIAL LANDS</div>
          <div className="swamplands_card_total">
            Total Supply: <span>3,000 lands</span>
          </div>
          <div className="swamplands_card_button">
            <button
              onClick={() =>
                window.open("https://www.youtube.com/watch?v=jUke3N7DnHE")
              }
            >
              {"MORE INFO >>"}
            </button>
          </div>
        </div>
      </div>

      <div className="swamplands_question">
        Want to learn more about lands? Watch our video:
      </div>
      <SmallButton
        onClick={() =>
          window.open("https://www.youtube.com/watch?v=jUke3N7DnHE")
        }
      >
        WATCH VIDEO
      </SmallButton>
      <div className="swamplands_conclusion">
        Lands can be obtained from a Cardano NFT marketplace, once they are
        live. SwampLands will be available to be withdrawn, sold and used as
        NFTs, although withdrawing is not a requirement to stake or use lands in
        the Swamp Land ecosystem.
      </div>
      <div className="swamplands_bottom">
        <div className="cycle-dashboard">
          <div className="cycle-dashboard__front-wrapper">
            <div className="cycle-dashboard__front-item" />
          </div>
          <div className="cycle-dashboard__back-wrapper">
            <div className="cycle-dashboard__back-item" />
          </div>
        </div>

        <div className="swamplands_bottom_content">
          <span className="flex-item">
            We have developed a special dashboard for convenient management of
            your Swamplands.
          </span>
          <SmallButton
            className="flex-item"
            onClick={() =>
              window.open("https://swamplands.cardanocrocsclub.com")
            }
          >
            TO DASHBOARD
          </SmallButton>
        </div>
      </div>
    </div>
  );
};

export default SwamplandsSection;
