import AsungCrocImg from "../../assets/team/members/asung_croc.png";
import MichorCrocImg from "../../assets/team/members/michor_croc.png";
import BofkeCrocImg from "../../assets/team/members/bofke_croc.png";

import ModeratorCrocImg1 from "../../assets/team/moderators/croc_1.png";
import ModeratorCrocImg2 from "../../assets/team/moderators/croc_2.png";
import ModeratorCrocImg3 from "../../assets/team/moderators/croc_3.png";
import ModeratorCrocImg4 from "../../assets/team/moderators/croc_4.png";
import ModeratorCrocImg5 from "../../assets/team/moderators/croc_5.png";

import teamImgAnna from "../../assets/team/developers/anna.png";
import teamImgSnizhana from "../../assets/team/developers/snizhana.png";
import teamImgPavlo from "../../assets/team/developers/pavlo.png";
import teamImgVlad from "../../assets/team/developers/vlad.png";
import teamImgNazar from "../../assets/team/developers/nazar.png";
import teamImgEdik from "../../assets/team/developers/edik.png";
import teamImgDima from "../../assets/team/developers/dima.png";
import teamImgBohdan from "../../assets/team/developers/bohdan.png";
import teamImgVolodymyr from "../../assets/team/developers/Volodymyr.png";
import teamImgSaygo from "../../assets/team/developers/saygo.png";
import teamImgAnton from "../../assets/team/developers/anton.png";
import teamImgOleksandr from "../../assets/team/developers/oleksandr.png";
import teamImgDiana from "../../assets/team/developers/diana.png";
import teamImgSerhiy from "../../assets/team/developers/serhiy.png";
import teamImgSerhiiy from "../../assets/team/developers/serhiiy.png";
import teamImgEldar from "../../assets/team/developers/eldar.png";
import teamImgVladyslav from "../../assets/team/developers/vladyslav.png";
import teamImgVitaliy from "../../assets/team/developers/vitaliy.png";

export const TeamMembersData = {
  heading: "CCC Team Member",
  members: [
    {
      name: "aSung",
      role: "CCC Team Member",
      desc: "Engineer by day, discord bot by night. aSung is the numbers guy and loves to hear himself talk on Twitter Spaces. Advocate for the financial independence / personal finance. Hoping to change lives for the better through NFTs and crypto.",
      img: AsungCrocImg,
    },
    {
      name: "Michor",
      role: "CCC Team Member",
      desc: "Cardano NFT maxi and supporter of many projects. Acts with best intentions and is always willing to help out. Believer that hard work pays off and is a real people-person. His glass is half-full; a positive thinker and a slow drinker 😝",
      img: MichorCrocImg,
    },
    {
      name: "Bofke",
      role: "CCC Team Member",
      desc: "NFT's are my passion, my goal is to improve the CNFT space and provide everyone with a pleasant experience. Through organizing Discord communities, providing assistance, and enhancing the overall CNFT environment, I strive to create an inclusive and enjoyable community for all.",
      img: BofkeCrocImg,
    },
  ],
};
export const ModeratorsData = {
  heading: "CCC Moderators",
  members: [
    {
      name: "Dom",
      role: "CCC moderator, HTC Team Member",
      desc: "Husband and father, loves making stuff and playing games.",
      img: ModeratorCrocImg1,
    },
    {
      name: "C4demus",
      role: "CCC Moderator",
      desc: "Crypto/NFT enthusiast and discord addict. As one of the OG discord members CCC blessed me by naming our token in my honor! My DMs are open!",
      img: ModeratorCrocImg2,
    },
    {
      name: "Oliver James",
      role: "CCC Moderator",
      desc: "An OG Pink Croc, frequently found in Twitter Spaces. Known as Honest Ollie on the mean streets of Cardano.",
      img: ModeratorCrocImg3,
    },
    {
      name: "Tertlface",
      role: "CCC Moderator",
      desc: "Nurse, Respiratory Therapist, Crypto Enthusiast, intermittent wood turner ...but not necessarily in that order. Devoted OG Croc since the very beginning. Croc family for life!",
      img: ModeratorCrocImg4,
    },
    {
      name: "Darkwing",
      role: "CCC Moderator",
      desc: "Lover of animals and pictures of animals. Wants to go to the moon",
      img: ModeratorCrocImg5,
    },
  ],
};
export const DevelopersData = {
  heading: "CCC Developers",
  members: [
    {
      name: "Cappadocia",
      role: "Project Manager",
      desc: "",
      img: teamImgAnna,
    },
    {
      name: "Pavlo",
      role: "Project Manager",
      desc: "",
      img: teamImgPavlo,
    },
    {
      name: "Snizhana",
      role: "Project Manager",
      desc: "",
      img: teamImgSnizhana,
    },
    {
      name: "SayGo",
      role: "Poker Team Lead",
      desc: "",
      img: teamImgSaygo,
    },
    {
      name: "Nazarii",
      role: "Devs Team Lead",
      desc: "",
      img: teamImgNazar,
    },
    {
      name: "Vlad",
      role: "Front-end Developer",
      desc: "",
      img: teamImgVlad,
    },
    {
      name: "Dima",
      role: "Front-end Developer",
      desc: "",
      img: teamImgDima,
    },
    {
      name: "Volodymyr",
      role: "Poker Developer",
      desc: "",
      img: teamImgVolodymyr,
    },
    {
      name: "Eduard",
      role: "Back-end Developer",
      desc: "",
      img: teamImgEdik,
    },
    {
      name: "Bohdan",
      role: "Game Developer",
      desc: "",
      img: teamImgBohdan,
    },

    {
      name: "Oleksandr",
      role: "Game Developer",
      desc: "",
      img: teamImgOleksandr,
    },
    {
      name: "Anton",
      role: "Flutter Developer",
      desc: "",
      img: teamImgAnton,
    },
    {
      name: "Serhiy",
      role: "Ui/UX Designer",
      desc: "",
      img: teamImgSerhiy,
    },
    {
      name: "Diana",
      role: "Illustrator",
      desc: "",
      img: teamImgDiana,
    },
    {
      name: "Serhiy",
      role: "Graphic Designer",
      desc: "",
      img: teamImgSerhiiy,
    },
    {
      name: "Eldar",
      role: "Graphic Designer",
      desc: "",
      img: teamImgEldar,
    },
    {
      name: "Vladyslav",
      role: "Videographer",
      desc: "",
      img: teamImgVladyslav,
    },
    {
      name: "Vitaliy",
      role: "Copywriter",
      desc: "",
      img: teamImgVitaliy,
    },
  ],
};

// Cappadocia - Project manager
// Snizhana - Project Manager
// Pavlo - Project Manager
// Nazarii - Devs Team Lead / Front-end developer?
// Vlad - Front-end Developer
// Dima - Front-end Developer
// Eduard - Back-end Developer
// Bohdan - Game Developer
// Volodymyr - Poker developer
// SayGo - Poker Team Lead
// Oleksandr - Game developer
// Anton - Android Developer
// Serhiy - Ui/UX Designer
// Diana - Illustrator
// Eldar - Graphic Designer
// Serhiy - Graphic Designer
// Vladyslav - Videographer
// Vitaliy - Copywriter
