import React, { useState } from "react";
import "./index.scss";
import CrocImg from "../../assets/investments/croc.svg";
import CoinUpImg from "../../assets/investments/coin_up.png";
import CoinDownImg from "../../assets/investments/coin_down.png";
import CoinsImg from "../../assets/investments/coins.svg";
import BoxMainImg from "../../assets/investments/rect1.png";
import BoxSecondImg from "../../assets/investments/rect2.png";
import SmallButton from "../../toolkits/SmallButton";
import Radioactive from "../../toolkits/Radioactive";

const C4InvestmentsSection = () => {
  const InvestBorder = () => (
    <div className="invest_border">
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="2" height="40" fill="white" />
        <rect width="40" height="2" fill="white" />
      </svg>
    </div>
  );

  const [inputValue, setInputValue] = useState("");

  return (
    <div className="invest_container">
      <div className="invest_mainBox">
        <InvestBorder />
        <InvestBorder />
        <InvestBorder />
        <InvestBorder />
        <div className="flex-item">
          <div className="invest_animationBox">
            <img className="crocImg" src={CrocImg} alt="croc" />

            <div className="invest_rectBox">
              <img src={BoxMainImg} alt="box1" />
              <img src={BoxMainImg} alt="box2" />
              <img src={BoxSecondImg} alt="box3" />
              <img src={BoxSecondImg} alt="box4" />
              <p>$C4 Investments</p>
            </div>
            <img className="coinUpImg" src={CoinUpImg} alt="coin1" />
            <img className="coinDownImg" src={CoinDownImg} alt="coin2" />
            <div className="coinGroup">
              <img src={CoinUpImg} alt="croc" />
              <img src={CoinDownImg} alt="croc" />
              <img src={CoinDownImg} alt="croc" />
            </div>

            <img className="coinsImg" src={CoinsImg} alt="croc" />
          </div>
        </div>
        <div className="flex-item invest_descBox">
          <p>
            $C4 Crowdfunding has a unique business model that combines
            real-world investments with a strong business ecosystem that uses
            the token as a medium of exchange and store of value.
          </p>
          <p>
            This makes it an attractive investment opportunity for those looking
            for a token that is backed up by real assets and has a strong use
            case.
          </p>
        </div>
      </div>

      <div
        id="unisender-subscribe-form"
        data-url="https://cp.unisender.com/ru/v5/subscribe-form/view/6idcjb4en3fh6pzds8adi7578fp5i3hqta1n1y6e"
        data-settings="6qb8r6jpwhqgpd7o3p69on6yonxk3b77otzo4woeiqe9z1buomojy"
      >
        {" "}
        <div className="invest_formBox">
          <Radioactive className="rad" />
          <Radioactive className="rad" />

          <p className="invest_formBox_heading">
            Stay up to date with the news — subscribe to the newsletter
          </p>
          <p className="invest_formBox_subHeading">
            We promise not to spam<span> ;)</span>
          </p>
          <form
            method="POST"
            // action="https://cp.unisender.com/ru/subscribe?hash=68ry7bn3cotwas3n1syx1sie9pfeftshgctc9c7mq4exdrfrr1xcy"
            action="https://cp.unisender.com/en/subscribe?hash=6qb8r6jpwhqgpd7o3p69on6yonxk3b77otzo4woeiqe9z1buomojy"
            name="subscribtion_form"
          >
            <div className="subscribe-form-item subscribe-form-item--input-email">
              <input
                className="subscribe-form-item__control subscribe-form-item__control--input-email"
                type="text"
                name="email"
                placeholder="Enter your e-mail"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="subscribe-form-item subscribe-form-item--btn-submit">
              <SmallButton className="subscribe-form-item__btn subscribe-form-item__btn--btn-submit invest_formBox_button">
                subscribe
              </SmallButton>
            </div>
            <input type="hidden" name="charset" value="UTF-8" />
            <input type="hidden" name="default_list_id" value="1" />
            <input type="hidden" name="overwrite" value="2" />
            <input type="hidden" name="is_v5" value="1" />
          </form>

          <p className="invest_formBox_policy">
            By clicking the "SUBSCRIBE" button you agree to{" "}
            <a href="/">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default C4InvestmentsSection;
